<template>
  <div>
        <el-row type="flex" justify="between" style="margin-top:10px;margin-left:10px">
            <el-col :span="5" :offset="20">
                <el-button type="warning" size="medium" icon="el-icon-plus" @click="addCorpDialogVisible = true">新增企业</el-button>
            </el-col>
        </el-row>
      <el-table :data="accountList" border stripe style="width: 100%" max-height="900px">
        <el-table-column label="企业" prop="account_name" align="center"></el-table-column>
        <el-table-column label="企飞id" prop="corp_id" align="center"></el-table-column>
        <el-table-column label="企飞账号" prop="account" align="center" ></el-table-column>
        <el-table-column label="操作" align="center" >
             <template slot-scope="scope">
                <router-link :to="`/channels12wasfgrdfsa?corp_id=${scope.row.corp_id}`">
                    <el-button type="primary" size="medium" >渠道列表</el-button>
                </router-link>
            </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="更新cookie"
        :visible.sync="updateCookieDialogVisible"
        width="30%"
        >
        <el-row :gutter="5">
            <el-col :span="6" >
                <span>更新cookie为:</span>
            </el-col>
            <el-col :span="15" >
                <el-input  size="mini" v-model="new_cookie"></el-input>
            </el-col>
        </el-row>

        <span slot="footer" class="dialog-footer">
            <el-button @click="updateCookieDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="updateCookie">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog
        title="新增企业"
        :visible.sync="addCorpDialogVisible"
        width="35%"
        >
        <el-form ref="form" :model="addCorpForm" :rules="rules" label-width="100px">
            <el-form-item label="企业名称" prop="account_name">
                <el-input v-model="addCorpForm.account_name" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="企飞用户名" prop="account">
                <el-input v-model="addCorpForm.account" placeholder="请输入企飞用户名"></el-input>
            </el-form-item>
            <el-form-item label="企飞密码" prop="password">
                <el-input v-model="addCorpForm.password" placeholder="请输入企飞密码"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addCorpDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addCorp">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
    created() {
        this.getAccountList()
    },
    data() {
        return {
            accountList: [],
            id: "",
            new_cookie: "",
            updateCookieDialogVisible: false,
            addCorpDialogVisible: false,
            addCorpForm: {
                account_name: "", 
                account: "",
                password: ""
            },
            rules:{
                account_name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                account: [{ required: true, message: '请输入企飞用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入企飞密码', trigger: 'blur' }],
            }
        }
       
    },
    methods: {
        async getAccountList() {
            // TODO 这里后面要根据登录的用户，查询对应的账户
            const {data: res} = await this.$http.get('account/1')
            if (res.status !== 1) return this.$message.error(res.message)
            // this.$message.success(res.message)
            this.accountList = res.result

        },
        showupdateCookieDialog(account){
            this.updateCookieDialogVisible = true
            this.id = account.id
        },
        async updateCookie(){

            const {data :res} = await this.$http.post('update_account', {"id": this.id, "cookie": this.new_cookie})
            if (res.status !== 1) return this.$message.error(res.message)
            this.updateCookieDialogVisible = false
            this.new_cookie = ""
            this.getAccountList()
        },
        async addCorp(){
            this.addCorpDialogVisible = false
            const {data :res} = await this.$http.post('account', this.addCorpForm)
            if (res.status !== 1) return this.$message.error(res.message)
            this.$message.success(res.message)
            this.getAccountList()
        }
    }
}
</script>

<style scoped>
.el-input{
    width: 300px;
}
</style>>