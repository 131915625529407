<template>
    <el-container class="home-container">

        <!-- 头部区域 -->
        <el-header height="100px">
            <div class="info">
                <p>以下是您所绑定渠道码的推广情况（单位：人）：</p>

                <ul class="titleStatistics">
                    <li class="title-item">
                        <span>今日关注</span>
                        <span> {{ overview.today_add_num }} </span>
                    </li>
                    <li class="title-item">
                        <span>累计关注</span>
                        <span> {{ overview.all_add_num }} </span>
                    </li>
                    <li class="title-item">
                        <span>累计取关</span>
                        <span> {{ overview.all_leave_num }} </span>
                    </li>
                    <li class="title-item">
                        <span>累计净增</span>
                        <span> {{ overview.net_increase_num }} </span>
                    </li>
                </ul>
            </div>
        </el-header>

        <!-- 主体区域 -->
        <el-main>
            <el-table 
            :data="channelStatistics" 
            border 
            stripe 
            height="100%"
            :cell-style="{padding: '8px 0'}">
            <el-table-column label="日期" prop="stat_date" sortable align="center"></el-table-column>
            <el-table-column label="新增好友数" align="center">
                <template slot-scope="scope">
                    {{Math.ceil(scope.row.add_num)}}
                </template>
            </el-table-column>
            <el-table-column label="留存好友数" align="center">
                <template slot-scope="scope">
                    {{Math.ceil(scope.row.retain_num)}}
                </template>
            </el-table-column>
            <el-table-column label="流失好友数" align="center">
                <template slot-scope="scope">
                    {{Math.ceil(scope.row.add_num) - Math.ceil(scope.row.retain_num)}}
                </template>
            </el-table-column>
        </el-table>
        </el-main>
    

      
      </el-container>
</template>

<script>

export default {
    created() {
       this.getStatistics()
       this.timer = setInterval(() => {
            setTimeout(() => {
            this.getStatistics()
            }, 0)
        }, 30000)
    },
    destroyed () {
        clearInterval(this.timer)
        },
    data() {
        return {
            channelStatistics: [],
            timer: null,
            overview: {
                // 今日关注
                today_add_num: 0,
                // 累计关注
                all_add_num: 0,
                // 累计取关
                all_leave_num: 0,
                // 累计净增
                net_increase_num: 0 
            }
        }
       
    },
    methods: {
        async getStatistics() {
            const {data: res} = await this.$http.get(`statistics/${this.$route.query.channel_id}/2`)
            if (res.status !== 1) return this.$message.error(res.message)
            // this.$message.success(res.message)
            this.channelStatistics = res.result.data
            this.overview = res.result.overview
        }
    }
}
</script>

<style scoped>

.home-container {
  height: 100%;
  width: 100%
  }

.info {
    position: relative;
    height: 120px;
    padding: 5px;
    font-size: 15px;
    color: #fff;
    background-color: #0F88EB;
}

.titleStatistics {
    display: flex;
    padding: 0px 0;
}

.title-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.el-table{
    padding: 0px;
    margin: 0px;
    width: 100%;
    background-color: #fff;
    padding: 0 15px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.el-header{
    padding: 0 0px;
}

.el-main{
    padding: 0 0px;
}
</style>