<template>
  <div>
        <el-row type="flex" justify="between" style="margin-top:10px;margin-left:10px">
        <!-- span表示每列div的宽度 -->
            <el-col :span="12" >
                <el-input placeholder="请输入查询的渠道" @keyup.enter.native="getChannelList" v-model="queryInfo.query" clearable @clear="getChannelList">
                    <el-button slot="append" icon="el-icon-search" @click="getChannelList"></el-button>
                </el-input>
            </el-col>

            <el-col :span="5" :offset="16">
                <router-link :to="`/sadewasdawqsasda`">
                    <el-button type="primary" size="medium" icon="el-icon-s-home">返回首页</el-button>
                </router-link>  

            </el-col>
        </el-row>

    <el-table 
        :data="channelList"
        :cell-style="{padding: '8px 0'}"
        border
        stripe>
        <el-table-column label="渠道名称" prop="channel_name" align="center" width="200px;"></el-table-column>
        <el-table-column label="默认品牌折扣" prop="default_brand_discount" align="center" width="180px;"></el-table-column>
        <el-table-column label="明日默认品牌折扣（明日生效）" prop="new_brand_discount" align="center" width="220px;"></el-table-column>
        <el-table-column label="默认中间人折扣" prop="default_middle_discount" align="center" width="180px;"></el-table-column>
        <el-table-column label="明日默认中间人折扣（明日生效）" prop="new_middle_discount" align="center" width="220px;"></el-table-column>
        <el-table-column label="操作" align="center">
             <template slot-scope="scope">
                <div>
                    <router-link :to="`/statistics_sdeaweqasf?corp_id=${queryInfo.corp_id}&channel_id=${scope.row.channel_id}`">
                        <el-button type="primary" size="medium">品牌</el-button>
                    </router-link>

                    <router-link :to="`/statistics_asdqwasxc?corp_id=${queryInfo.corp_id}&channel_id=${scope.row.channel_id}`">
                        <el-button type="primary" size="medium" style="margin-left:10px;">中间人</el-button>
                    </router-link>

                    <router-link :to="`/modify_statistics_sewdsada?corp_id=${queryInfo.corp_id}&channel_id=${scope.row.channel_id}`">
                        <el-button type="warning" style="margin-left:10px;" size="medium">编辑数据</el-button>
                    </router-link>

                    <el-button type="danger" style="margin-left:10px;" size="medium" @click="showModifyDiscountDialog(scope.row)">修改明日折扣</el-button>
                </div>
            </template>
        </el-table-column>
      </el-table>

    <!-- 分页区域 -->
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <el-dialog
        title="修改折扣，明日生效！！"
        :visible.sync="modifyDiscountDialogVisible"
        width="30%"
        >
        <el-row :gutter="16" class="dialog-row">
            <el-col :span="10">
                <span>品牌折扣修改折扣为：</span>
            </el-col>
            <el-col :span="6">
                <!-- 品牌折扣修改折扣为： -->
                <el-input-number v-model="new_brand_discount" :precision="2" :step="0.1" :max="1" :min="0"></el-input-number>
                <!-- <el-input  size="mini" v-model="new_brand_discount"></el-input> -->
            </el-col>
        </el-row>

        <el-row :gutter="16" class="dialog-row">
            <el-col :span="10">
                <span>中间人折扣修改折扣为：</span>
            </el-col>
            <el-col :span="6">
                <el-input-number v-model="new_middle_discount" :precision="2" :step="0.1" :max="1" :min="0"></el-input-number>
                <!-- <el-input  size="mini" v-model="new_middle_discount"></el-input> -->
            </el-col>
        </el-row>

        <span slot="footer" class="dialog-footer">
            <el-button @click="modifyDiscountDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="modifyDiscount">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>

export default {

    created() {
        this.queryInfo.corp_id = this.$route.query.corp_id;
        this.getChannelList()
        this.timer = setInterval(() => {
            setTimeout(() => {
            this.getChannelList()
            }, 0)
        }, 30000)
    },
    destroyed () {
        clearInterval(this.timer)
        },
    data() {
        return {
            channelList: [],
            queryInfo:{
                query: '',
                pagenum: 1,
                pagesize: 10,
                corp_id: ''
            },
            total: 0,
            modifyDiscountDialogVisible: false,
            // 修改后的新折扣
            new_brand_discount: '',
            new_middle_discount: '',
            // 要修改折扣的渠道id
            channel_id: ''
        }
       
    },
    methods: {

        async getChannelList() {
            if (this.queryInfo.query == ''){
                const {data: res} = await this.$http.get(`channel/-1/${this.queryInfo.pagenum}/${this.queryInfo.pagesize}/${this.queryInfo.corp_id}`)
                if (res.status !== 1) return this.$message.error(res.message)
                this.channelList = res.result.data
                this.total = res.result.total
            }
            else{
                const {data: res} = await this.$http.get(`channel/${this.queryInfo.query}/${this.queryInfo.pagenum}/${this.queryInfo.pagesize}/${this.queryInfo.corp_id}`)
                if (res.status !== 1) return this.$message.error(res.message)
                this.channelList = res.result.data
                this.total = res.result.total
            }

        },
        async channelStateChanged(channel) {

            const {data: res} = await this.$http.post(`change_channel_state/${channel.channel_id}/${channel.if_start}`)
            if(res.status !== 1){
                    // 如果失败，要修改回用户状态
                    channel.if_start = !channel.if_start
                    return this.$message.error(res.message)
                }
                return this.$message.success(res.message)

        },
        handleSizeChange(newPageSize){
            this.queryInfo.pagesize = newPageSize
                // 重新发起请求
            this.getChannelList()
            },
        handleCurrentChange(newPageNum){
            this.queryInfo.pagenum = newPageNum
                // 重新发起请求
            this.getChannelList()
            
        },
        showModifyDiscountDialog(channel){
            this.modifyDiscountDialogVisible = true
            this.channel_id = channel.channel_id
            if(channel.new_brand_discount === null){
                this.new_brand_discount = channel.brand_discount
            }else{
                this.new_brand_discount = channel.new_brand_discount
            }
            if(channel.new_middle_discount === null){
                this.new_middle_discount = channel.middle_discount
            }else{
                this.new_middle_discount = channel.new_middle_discount
            }
        },
        async modifyDiscount(){
            
            const {data: res} = await this.$http.post('change_channel_discount', {"channel_id": this.channel_id,
                                                                                    "middle_discount": this.new_middle_discount,
                                                                                    "brand_discount": this.new_brand_discount})
            if(res.status !== 1){
                    return this.$message.error(res.message)
                }
            
            this.$message.success(res.message)
            this.modifyDiscountDialogVisible = false
            this.getChannelList()
        }
    }
}
</script>

<style scoped>
.dialog-row{
    display: flex;
    height: 80px;
    align-items: center;
    margin-bottom:10px;
    font-size: 16px;
}
</style>