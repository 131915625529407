<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header height="100px">
        <div class="info">
                <p>以下是您所绑定渠道码的推广情况（单位：人）：</p>

                <ul class="titleStatistics">
                    <li class="title-item">
                        <span>今日关注</span>
                        <span> {{ overview.today_add_num }} </span>
                    </li>
                    <li class="title-item">
                        <span>累计关注</span>
                        <span> {{ overview.all_add_num }} </span>
                    </li>
                    <li class="title-item">
                        <span>累计取关</span>
                        <span> {{ overview.all_leave_num }} </span>
                    </li>
                    <li class="title-item">
                        <span>累计净增</span>
                        <span> {{ overview.net_increase_num }} </span>
                    </li>
                </ul>
            </div>
    </el-header>
    <!-- 主体区域 -->
    <el-main>
      <el-table 
      :data="channelStatistics" 
      border 
      stripe
      height="100%"
      :cell-style="{padding: '8px 0'}">
        <el-table-column label="日期" prop="stat_date" align="center" width="180px;"></el-table-column>
        <el-table-column label="实际/修改后新增数" prop="add_num" align="center" width="180px;">
            <template slot-scope="scope">
                {{scope.row.add_num}} / {{scope.row.modified_add_num ? scope.row.modified_add_num : '-'}}
            </template>
        </el-table-column>
        <!-- <el-table-column label="修改后的新增好友数" prop="modified_add_num"></el-table-column> -->
        <el-table-column label="实际留存数" prop="retain_num" align="center" width="180px;"></el-table-column>
        <el-table-column label="实际/修改后的留存率" align="center" width="180px;">
            <template slot-scope="scope">
                {{scope.row.retain_rate}} / {{scope.row.modified_retain_rate ? scope.row.modified_retain_rate : '-'}}
            </template>
        </el-table-column>
        <el-table-column label="实际流失数" align="center" width="180px;">
            <template slot-scope="scope">
                {{scope.row.add_num - scope.row.retain_num}}
            </template>
        </el-table-column>
        <!-- <el-table-column label="折扣" prop="discount" align="center"></el-table-column> -->
        <el-table-column label="启用修改新增数" prop="modified_add_num_state" align="center" width="180px;">

            <template slot-scope="scope">
                <el-switch 
                    v-model="scope.row.modified_add_num_state"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="1"
                    inactive-value="0"
                    @change="modifyStateChanged(scope.row)"
                    v-if="scope.row.order === 0"
                    active-text="启用"
                    inactive-text="禁用">
                </el-switch>
            </template>
                <!-- :disabled="true ? scope.row.order !== 0 : false" -->
        </el-table-column>
        <el-table-column label="操作" align="center">
            <template slot-scope="scope">
                <el-button type="primary" size="medium" @click="showModifyAddDialog(scope.row)">修改新增数</el-button>
                <el-button type="warning" size="medium" @click="showModifyRetainDialog(scope.row)">修改留存率</el-button>
            </template>
        </el-table-column>
      </el-table>
    
        <el-dialog
            title="修改新增数"
            :visible.sync="modifyAddDialogVisible"
            width="30%"
            >
            <el-row :gutter="16" class="dialog-row">
                <el-col :span="6">
                    <span>修改新增数：</span>
                </el-col>
                <el-col :span="6">
                    <el-input-number v-model="modified_add_num" :step="1"  :min="0"></el-input-number>
                    <!-- <el-input  size="mini" v-model="modified_add_num"></el-input> -->
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button @click="modifyAddDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="modifyAdd()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="修改留存率"
            :visible.sync="modifyReatinDialogVisible"
            width="30%"
            >
            <el-row :gutter="16" class="dialog-row">
                <el-col :span="6">
                    <span>修改留存率：</span>
                </el-col>
                <el-col :span="6">
                    <el-input-number v-model="modified_retain_rate" :precision="2" :step="0.01" :max="1" :min="0"></el-input-number>
                    <!-- <el-input  size="mini" v-model="modified_retain_rate"></el-input> -->
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button @click="modifyReatinDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="modifyRetainRate()">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
  </el-container>
</template>

<script>

export default {
    created() {
       this.getStatistics()
    },
    data() {
        return {
            channelStatistics: [],
            modifyAddDialogVisible: false,
            modifyReatinDialogVisible: false,
            statistics_id: '',
            modified_add_num: '',
            modified_retain_rate: '',
            overview: {
                // 今日关注
                today_add_num: 0,
                // 累计关注
                all_add_num: 0,
                // 累计取关
                all_leave_num: 0,
                // 累计净增
                net_increase_num: 0 
            },
            
        }
       
    },
    methods: {
        async getStatistics() {
            const {data: res} = await this.$http.get(`statistics/${this.$route.query.channel_id}/1`)
            if (res.status !== 1) return this.$message.error(res.message)
            // this.$message.success(res.message)
            this.channelStatistics = res.result.data
            // console.log("channelStatistics = ", this.channelStatistics);
            this.overview = res.result.overview
        },

        async modifyStateChanged(statistics) {
            const post_data = {"corp_id": this.$route.query.corp_id,
                                "channel_id": statistics.channel_id,
                                "statistics_id": statistics.id,
                                "modified_add_num_state": statistics.modified_add_num_state}
            const {data: res} = await this.$http.post("change_modify_state", post_data)
            if(res.status !== 1){
                    // 如果失败，要修改回用户状态
                    statistics.discount_state = !statistics.discount_state
                    return this.$message.error(res.message)
                }
                return this.$message.success(res.message)

        },
        async modifyAdd() {
            const post_data = {"statis_id": this.statistics_id,
                                "modified_add_num":  this.modified_add_num
                                }

            const {data: res} = await this.$http.post('update_statis_data', post_data)
            if(res.status !== 1){
                    return this.$message.error(res.message)
                }
            this.$message.success(res.message)
            this.modifyAddDialogVisible = false
            this.getStatistics()
            
        },
        showModifyAddDialog(statistics){
            this.modifyAddDialogVisible = true
            this.statistics_id = statistics.id
            if(statistics.modified_add_num === null){
                this.modified_add_num = statistics.add_num
            }else{
                this.modified_add_num = statistics.modified_add_num
            }
        },

        showModifyRetainDialog(statistics){
            this.modifyReatinDialogVisible = true
            this.statistics_id = statistics.id

            if(statistics.modified_retain_rate === null){
                this.modified_retain_rate = statistics.retain_rate
            }else{
                this.modified_retain_rate = statistics.modified_retain_rate
            }
            
        },
        async modifyRetainRate(){
            // console.log("statistics_id = ", this.statistics_id);
            const post_data = {"statis_id": this.statistics_id,
                                "modified_retain_rate":  this.modified_retain_rate
                                }

            const {data: res} = await this.$http.post('update_statis_data', post_data)
            if(res.status !== 1){
                    return this.$message.error(res.message)
                }
            this.$message.success(res.message)
            this.modifyReatinDialogVisible = false
            this.getStatistics()
        }

        
    }
}
</script>

<style scoped>
.home-container {
  height: 100%;
  width: 100%
  }

.info {
    position: relative;
    height: 120px;
    padding: 5px;
    font-size: 15px;
    color: #fff;
    background-color: #0F88EB;
}

.titleStatistics {
    display: flex;
    padding: 0px 0;
}

.title-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.el-table{
    padding: 0px;
    margin: 0px;
    width: 100%;
    background-color: #fff;
    padding: 0 15px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.el-header{
    padding: 0 0px;
}

.el-main{
    padding: 0 0px;
}

.dialog-row{
    display: flex;
    height: 80px;
    align-items: center;
    margin-bottom:10px;
    font-size: 16px;
}
</style>