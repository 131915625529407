import Vue from 'vue'
import { Button, Table, TableColumn, Message, Input, Switch, Dialog, Row, Col, Pagination, 
    Header, Container, Main, InputNumber, Form, FormItem } from 'element-ui'

Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(Row)
Vue.use(Col)
Vue.use(Pagination)
Vue.use(Header)
Vue.use(Container)
Vue.use(Main)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(InputNumber)
Vue.prototype.$message = Message
