import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Channels from '../components/Channels.vue'
import BrandStatistics from '../components/BrandStatistics.vue'
import MiddleStatistics from '../components/MiddleStatistics.vue'
import ModifyStatistics from '../components/ModifyStatistics.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/sadewasdawqsasda', component: Home},
  { path: '/channels12wasfgrdfsa', component: Channels},
  // 品牌查看页面，打折+修改数据
  { path: '/statistics_sdeaweqasf', component: BrandStatistics},
  // 中间人查看页面，打折
  { path: '/statistics_asdqwasxc', component: MiddleStatistics},
  { path: '/modify_statistics_sewdsada', component: ModifyStatistics}
]

const router = new VueRouter({
  routes
})

export default router
